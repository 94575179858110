import React from 'react';
import { FaLinkedin, FaTwitter, FaFacebook } from 'react-icons/fa';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer id="contact" className="bg-primary text-white py-12">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    <div className="mb-8 md:mb-0">
                        <h3 className="text-xl font-semibold mb-4">Appnable Limited</h3>
                        <p className="text-sm">
                            Transforming workplaces with cutting-edge Microsoft 365 solutions. Enable your business today for tomorrow's challenges.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
                        <ul className="space-y-2">
                            {['About', 'Services', 'Technologies', 'Experience', 'Contact'].map((item) => (
                                <li key={item}>
                                    <a href={`#${item.toLowerCase()}`} className="hover:text-primary-light transition-colors duration-300">
                                        {item}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
                        <p className="mb-2">Email: <a href="mailto:info@appnable.com" className="hover:text-primary-light transition-colors duration-300">info@appnable.com</a></p>
                        <p className="mb-2">Phone: +44 7939 561 904</p>
                        <p>Address: 38 Rothesay Rd, Chapel Langley, Luton LU1 1QZ</p>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold mb-4">Follow Us</h4>
                        <div className="flex space-x-4">
                            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:text-primary-light transition-colors duration-300">
                                <FaLinkedin size={24} />
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-primary-light transition-colors duration-300">
                                <FaTwitter size={24} />
                            </a>
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-primary-light transition-colors duration-300">
                                <FaFacebook size={24} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="border-t border-white/10 mt-8 pt-8 text-center">
                    <p>&copy; {currentYear} Appnable Limited. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;