import React from 'react';
import { FaLightbulb, FaCogs, FaChartLine, FaHandshake } from 'react-icons/fa';

const AboutItem = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center text-center mb-8">
    <div className="bg-primary text-white rounded-full p-4 mb-4">
      <Icon size={24} />
    </div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const About = () => {
  const aboutItems = [
    {
      icon: FaLightbulb,
      title: "Innovative Solutions",
      description: "Leveraging 18+ years of experience to deliver cutting-edge Microsoft 365 solutions."
    },
    {
      icon: FaCogs,
      title: "Process Automation",
      description: "Streamlining workflows and boosting productivity through intelligent automation."
    },
    {
      icon: FaChartLine,
      title: "Digital Transformation",
      description: "Guiding organizations through seamless transitions to modern workplace technologies."
    },
    {
      icon: FaHandshake,
      title: "Tailored Approach",
      description: "Providing bespoke solutions that align perfectly with your unique business needs."
    }
  ];

  return (
    <section id="about" className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-primary mb-8 text-center">About Appnable Limited</h2>
        <p className="text-gray-700 text-center max-w-3xl mx-auto mb-12">
          Appnable Limited is a consultancy company at the forefront of digital transformation, specializing in harnessing the comprehensive capabilities of the Microsoft 365 ecosystem. We're dedicated to helping organizations unlock their full potential through innovative technology solutions.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {aboutItems.map((item, index) => (
            <AboutItem key={index} {...item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;