import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const CaseStudy = ({ title, description, achievements, additionalProjects }) => (
  <div className="bg-white rounded-lg shadow-lg p-8 mb-8 transition-all duration-300 hover:shadow-xl">
    <h3 className="text-2xl font-bold text-primary mb-4">{title}</h3>
    <p className="text-gray-700 mb-6">{description}</p>
    <h4 className="text-xl font-semibold text-secondary mb-4">Key Achievements:</h4>
    <ul className="mb-6">
      {achievements.map((achievement, index) => (
        <li key={index} className="flex items-start mb-2">
          <FaCheckCircle className="text-primary mt-1 mr-2 flex-shrink-0" />
          <span>{achievement}</span>
        </li>
      ))}
    </ul>
    {additionalProjects && (
      <>
        <h4 className="text-xl font-semibold text-secondary mb-4">Additional Projects:</h4>
        <ul>
          {additionalProjects.map((project, index) => (
            <li key={index} className="flex items-start mb-2">
              <FaCheckCircle className="text-primary mt-1 mr-2 flex-shrink-0" />
              <span>{project}</span>
            </li>
          ))}
        </ul>
      </>
    )}
  </div>
);

const CaseStudies = () => {
  const caseStudies = [
    {
      title: "Parole Board of England and Wales",
      description: "Since 2005, Appnable has been at the forefront of modernizing the Parole Board's document template system. Our journey with them showcases our ability to evolve with technology and consistently deliver innovative solutions.",
      achievements: [
        "Implemented VBA automation for Word templates, enhancing document creation efficiency.",
        "Developed VSTO and .NET-based solutions for seamless operation across member laptops.",
        "Created a cutting-edge deployment solution using Azure Cloud and Microsoft Intune.",
        "Continuously improved and updated templates to meet changing legal and operational needs."
      ]
    },
    {
      title: "Linklaters LLP - Template Management System Evolution",
      description: "Our partnership with Linklaters LLP showcases our ability to innovate and adapt to changing technological landscapes while maintaining user-friendly solutions.",
      achievements: [
        "Developed Linklaters Template Management (LTM) solution using C# and .NET Framework, upgrading from a VBA-based system.",
        "Successfully transitioned to Office 365 64-bit platform, ensuring compatibility and enhancing efficiency.",
        "Implemented a multi-component system including VSTO-based Office Addin, updater service, and Azure-based deployment pipeline.",
        "Reduced Word, Excel, and PowerPoint startup times from 15-20 seconds to 5-6 seconds.",
        "Maintained familiar user experience while significantly improving backend processes."
      ],
      additionalProjects: [
        "Project Lunar: Developed code to extract crucial information from over 5,000 documents, resulting in savings of approximately 600,000 euros.",
        "Productivity Tools: Led the development of tools like Dexter and Circling Up Tool, reducing processing time from 12-30 minutes to under 8 seconds using OpenXml and Word automation."
      ]
    }
  ];

  return (
    <section id="case-studies" className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-primary mb-8 text-center">Case Studies</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {caseStudies.map((study, index) => (
            <CaseStudy key={index} {...study} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CaseStudies;