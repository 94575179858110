import React, { useState, useEffect } from 'react';
import logo from '../images/appnable.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-white shadow-md' : 'bg-transparent'}`}>
      <div className="container mx-auto px-4 py-4 flex flex-wrap justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Appnable Logo" className="h-12 w-auto" />
          <div className="text-xs ml-2 mt-1 text-primary">enable you today for tomorrow.</div>
        </div>
        <button
          className={`md:hidden focus:outline-none ${isScrolled ? 'text-primary' : 'text-white'}`}
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
            {isMenuOpen ? (
              <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
            ) : (
              <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
            )}
          </svg>
        </button>
        <nav className={`${isMenuOpen ? 'block' : 'hidden'} md:flex w-full md:w-auto mt-4 md:mt-0`}>
          <ul className="md:flex md:space-x-4">
            {['Home', 'About', 'Services', 'Technologies', 'Experience', 'Contact'].map((item) => (
              <li key={item} className="px-4 py-2 md:py-0">
                <button
                  onClick={() => scrollToSection(item.toLowerCase())}
                  className={`${isScrolled ? 'text-gray-800' : 'text-white'} hover:text-primary-light transition-colors duration-300`}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <a
          href="#contact"
          className={`hidden md:inline-block ${isScrolled ? 'bg-primary text-white' : 'bg-white text-primary'} font-bold py-2 px-4 rounded-full hover:bg-primary-dark hover:text-white transition-colors duration-300 mt-4 md:mt-0`}
        >
          Get Started
        </a>
      </div>
    </header>
  );
};

export default Header;