import React from 'react';
import heroIllustration from '../images/hero-illustration.svg';

const Hero = () => {
  return (
    <div className="bg-gradient-to-r from-primary to-secondary text-white py-16 md:py-32 mt-16">
      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
        <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">Transforming Workplaces with Microsoft 365</h1>
          <p className="text-lg md:text-xl lg:text-2xl mb-8">Custom Solutions. Seamless Integration. Comprehensive Support.</p>
          <a href="#contact" className="bg-white text-primary font-bold py-3 px-6 rounded-full hover:bg-gray-100 transition duration-300 inline-block">
            Get Started
          </a>
        </div>
        <div className="w-full md:w-1/2 flex justify-center">
          <img
            src={heroIllustration}
            alt="Workplace transformation illustration"
            className="w-full max-w-md h-auto"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = 'https://via.placeholder.com/500x400?text=Workplace+Transformation';
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;