import React from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';

const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const markers = [
  { name: "United Kingdom", coordinates: [-0.1276, 51.5074] },
  { name: "United States", coordinates: [-95.7129, 37.0902] },
  { name: "France", coordinates: [2.3522, 48.8566] },
  { name: "Denmark", coordinates: [9.5018, 56.2639] },
  { name: "Sweden", coordinates: [18.0686, 59.3293] },
  { name: "United Arab Emirates", coordinates: [55.2708, 25.2048] },
  { name: "Nigeria", coordinates: [8.6753, 9.0820] },
];

const GlobalReach = () => {
  return (
    <section id="global-reach" className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-primary mb-8 text-center">Our Global Reach</h2>
        <div className="flex flex-wrap items-center justify-center">
          <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
            <div className="bg-white rounded-lg shadow-lg p-4">
              <ComposableMap projection="geoMercator" projectionConfig={{ scale: 100 }} width={500} height={300}>
                <Geographies geography={geoUrl}>
                  {({ geographies }) =>
                    geographies.map((geo) => (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill="#EAEAEC"
                        stroke="#D6D6DA"
                      />
                    ))
                  }
                </Geographies>
                {markers.map(({ name, coordinates }) => (
                  <Marker key={name} coordinates={coordinates}>
                    <circle r={3} fill="#F00" stroke="#fff" strokeWidth={2} />
                  </Marker>
                ))}
              </ComposableMap>
            </div>
          </div>
          <div className="w-full lg:w-1/2 lg:pl-8">
            <h3 className="text-xl font-semibold text-secondary mb-4">We've delivered services across:</h3>
            <ul className="flex flex-wrap">
              {markers.map(({ name }) => (
                <li key={name} className="bg-white text-gray-800 rounded-full px-4 py-2 m-2 shadow-md">
                  {name}
                </li>
              ))}
              <li className="bg-white text-gray-800 rounded-full px-4 py-2 m-2 shadow-md">And more...</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GlobalReach;