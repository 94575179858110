import React from 'react';
import { FaCheckCircle, FaMicrosoft, FaPuzzlePiece, FaRocket } from 'react-icons/fa';

const ReasonItem = ({ title, description, icon }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-4 transition-all duration-300 hover:shadow-lg hover:transform hover:scale-105">
    <div className="flex items-center mb-4">
      {icon}
      <h3 className="text-xl font-semibold text-primary ml-4">{title}</h3>
    </div>
    <p className="text-gray-700">{description}</p>
  </div>
);

const WhyChooseUs = () => {
  const reasons = [
    {
      title: "Proven Track Record",
      description: "With over 18 years of experience in developing Office Productivity applications, we have a strong history of delivering successful solutions across various sectors.",
      icon: <FaCheckCircle className="text-4xl text-primary" />
    },
    {
      title: "Microsoft 365 Ecosystem Expertise",
      description: "Our deep understanding of the Microsoft 365 ecosystem allows us to create comprehensive, integrated solutions that maximize your investment in Microsoft technologies.",
      icon: <FaMicrosoft className="text-4xl text-primary" />
    },
    {
      title: "Tailored Solutions",
      description: "We don't believe in one-size-fits-all. Our solutions are carefully crafted to address your specific challenges and opportunities.",
      icon: <FaPuzzlePiece className="text-4xl text-primary" />
    },
    {
      title: "Future-Ready Approach",
      description: "We're always looking ahead, incorporating emerging technologies like AI and low-code platforms to ensure your solutions remain cutting-edge.",
      icon: <FaRocket className="text-4xl text-primary" />
    }
  ];

  return (
    <section id="why-choose-us" className="mb-16 bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-primary mb-8 text-center">Why Choose Us?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {reasons.map((reason, index) => (
            <ReasonItem key={index} {...reason} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;