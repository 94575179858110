import React from 'react';

const TechnologyGroup = ({ title, technologies, description }) => (
  <div className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg">
    <h3 className="text-xl font-semibold text-primary mb-4">{title}</h3>
    <p className="text-gray-700 mb-4">{description}</p>
    <div className="flex flex-wrap">
      {technologies.map((tech, index) => (
        <span key={index} className="bg-gray-200 text-gray-800 rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2">
          {tech}
        </span>
      ))}
    </div>
  </div>
);

const Technologies = () => {
  const technologyGroups = [
    {
      title: "Microsoft 365 Suite",
      technologies: ["SharePoint", "Word", "Excel", "PowerPoint", "Teams"],
      description: "We leverage the full power of Microsoft 365 to enhance collaboration and productivity in your organization."
    },
    {
      title: "Power Platform",
      technologies: ["Power BI", "Power Apps", "Power Automate", "Microsoft Graph"],
      description: "Our expertise in the Power Platform allows us to create custom solutions that automate processes and provide valuable insights."
    },
    {
      title: "Cloud & DevOps",
      technologies: ["Azure Cloud", "Azure DevOps", "Microsoft Intune"],
      description: "We utilize Microsoft's cloud and DevOps tools to ensure scalable, secure, and efficient solutions for your business."
    },
    {
      title: "Development Technologies",
      technologies: [".NET Framework", "ASP.NET MVC", "VBA", "VSTO", "Office.JS", "SQL Server"],
      description: "Our development expertise spans a wide range of technologies, enabling us to create tailored solutions for any business need."
    },
    {
      title: "AI & Advanced Technologies",
      technologies: ["CoPilot (AI)", "Microsoft Graph"],
      description: "We harness the power of AI and advanced Microsoft technologies to drive innovation and efficiency in your workplace."
    }
  ];

  return (
    <section id="technologies" className="mb-16 bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-primary mb-4 text-center">Technologies We Leverage</h2>
        <p className="text-gray-700 text-center max-w-3xl mx-auto mb-12">
          At Appnable Limited, we specialize in a wide range of cutting-edge Microsoft technologies. Our expertise allows us to deliver comprehensive, integrated solutions that drive digital transformation and enhance productivity.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {technologyGroups.map((group, index) => (
            <TechnologyGroup key={index} {...group} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Technologies;