import React from 'react';

const ExperienceItem = ({ company, role, description }) => (
  <div className="mb-8 flex">
    <div className="flex-shrink-0 w-12 h-12 rounded-full bg-primary flex items-center justify-center text-white font-bold text-xl">
      {company[0]}
    </div>
    <div className="ml-4">
      <h3 className="text-xl font-semibold text-primary">{company}</h3>
      <p className="text-gray-600 italic">{role}</p>
      <p className="text-gray-700 mt-2">{description}</p>
    </div>
  </div>
);

const Experience = () => {
  const experiences = [
    {
      company: "Magic Circle Law Firm",
      role: "PowerPoint Integration Specialist",
      description: "Architected and developed a PowerPoint VSTO solution in C# that integrates with a third-party Digital Asset Management (DAM) system by Optimizely, synchronizing assets from the DAM system to SharePoint."
    },
    {
      company: "Credit Rating Agency",
      role: "Application Developer",
      description: "Spearheaded the development of sophisticated systems in C#.NET, focusing on applications for MS Word and Excel, enhancing application stability and performance in Excel VBA."
    },
    {
      company: "Magic Circle Law Firm",
      role: "Team Lead & Migration Specialist",
      description: "Managed and mentored a team of six developers, orchestrating the migration of over one hundred Excel, Word, and VBA-based solutions to a VSTO solution using C#.NET."
    },
    {
      company: "Fintech Company",
      role: "Lead Developer",
      description: "Led the development of GLML MS Word Plugin in a high-profile fintech project, utilizing VSTO, .NET framework, and Azure Cloud computing."
    }
  ];

  return (
    <section id="experience" className="mb-8">
      <h2 className="text-3xl font-bold text-primary mb-6">Our Experience</h2>
      <div className="space-y-8">
        {experiences.map((exp, index) => (
          <ExperienceItem key={index} {...exp} />
        ))}
      </div>
      <p className="text-gray-700 font-semibold mt-8">
        Our extensive experience spans various sectors, including financial services, legal, and technology, consistently delivering high-quality, innovative solutions.
      </p>
    </section>
  );
};

export default Experience;