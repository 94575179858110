import React from 'react';
import { FaCogs, FaRobot, FaCloud, FaDatabase, FaCode, FaGraduationCap } from 'react-icons/fa';

const ServiceItem = ({ title, description, icon }) => (
  <div className="bg-white rounded-lg shadow-md p-6 transition-transform duration-300 hover:shadow-lg hover:transform hover:-translate-y-2">
    <div className="flex items-center mb-4">
      <div className="text-4xl text-primary mr-4">{icon}</div>
      <h3 className="text-xl font-semibold text-primary">{title}</h3>
    </div>
    <p className="text-gray-700">{description}</p>
  </div>
);

const Services = () => {
  const services = [
    {
      title: "Tailored Microsoft 365 Solutions",
      description: "We craft custom solutions within the Microsoft 365 suite, enhancing collaboration and streamlining workflows.",
      icon: <FaCogs />
    },
    {
      title: "Process Automation",
      description: "Using Power Automate and AI Builder, we automate repetitive tasks, boosting efficiency and reducing operational costs.",
      icon: <FaRobot />
    },
    {
      title: "Legacy System Upgrades",
      description: "We specialize in transitioning existing Office applications to the cloud-based Microsoft 365 environment.",
      icon: <FaCloud />
    },
    {
      title: "Advanced Data Integration",
      description: "Our expertise in database technologies allows us to provide robust data solutions that integrate seamlessly with Microsoft 365.",
      icon: <FaDatabase />
    },
    {
      title: "Bespoke Software Development",
      description: "We develop and enhance custom applications tailored to specific needs, from analytical tools to document management systems.",
      icon: <FaCode />
    },
    {
      title: "Comprehensive Training and Support",
      description: "We provide extensive training and ongoing support for Microsoft 365 applications and custom solutions.",
      icon: <FaGraduationCap />
    }
  ];

  return (
    <section id="services" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-primary mb-4 text-center">Our Services</h2>
        <p className="text-gray-700 text-center max-w-3xl mx-auto mb-12">
          At Appnable Limited, we offer a comprehensive range of services designed to transform your workplace with cutting-edge Microsoft 365 solutions. Our expertise spans from tailored implementations to advanced integrations and ongoing support.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          {services.map((service, index) => (
            <ServiceItem key={index} {...service} />
          ))}
        </div>
        <div className="text-center">
          <a href="#contact" className="bg-primary text-white font-bold py-3 px-6 rounded-full hover:bg-primary-dark transition duration-300">
            Get Started with Our Services
          </a>
        </div>
      </div>
    </section>
  );
};

export default Services;