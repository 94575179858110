import React from 'react';
import { FaUserCircle, FaLightbulb, FaTachometerAlt, FaHandshake } from 'react-icons/fa';

const ApproachItem = ({ title, description, icon }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-4 flex flex-col items-center text-center transition-all duration-300 hover:shadow-lg hover:transform hover:scale-105">
    <div className="mb-4 text-5xl text-primary">{icon}</div>
    <h3 className="text-xl font-semibold text-primary mb-2">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

const Approach = () => {
  const approaches = [
    {
      title: "Client-Centric Focus",
      description: "We prioritize understanding your unique challenges and goals, ensuring our solutions align perfectly with your strategic objectives.",
      icon: <FaUserCircle />
    },
    {
      title: "Continuous Innovation",
      description: "Our team stays at the forefront of technological advancements, constantly exploring new ways to improve efficiency and productivity.",
      icon: <FaLightbulb />
    },
    {
      title: "Agile Development",
      description: "We employ agile methodologies to deliver rapid results and adapt quickly to changing requirements.",
      icon: <FaTachometerAlt />
    },
    {
      title: "Long-Term Partnership",
      description: "We believe in building lasting relationships with our clients, providing ongoing support and evolving our solutions as your needs change.",
      icon: <FaHandshake />
    }
  ];

  return (
    <section id="our-approach" className="mb-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-primary mb-8 text-center">Our Approach</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {approaches.map((approach, index) => (
            <ApproachItem key={index} {...approach} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Approach;